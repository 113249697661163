const stockData = [
    {
      "ticker": "AAPL",
      "eps": 203.69655969986965,
      "pe": 36.71741352365239,
      "pbr": 2.784907522722758,
      "beta": 1.0169750562996933,
      "regularPrice": 575.1511715242597,
      "priceSales": 60.169086346871325
    },
    {
      "ticker": "MSFT",
      "eps": 210.44278980132736,
      "pe": 5.929189546143043,
      "pbr": 0.24416707878838206,
      "beta": 0.18043065712751405,
      "regularPrice": 597.0241585997356,
      "priceSales": 64.28354279487094
    },
    {
      "ticker": "AMZN",
      "eps": 182.4110829357023,
      "pe": 48.479753773721676,
      "pbr": 2.398280732672977,
      "beta": 1.0116638841138754,
      "regularPrice": 69.44735095855717,
      "priceSales": 78.48801989863105
    },
    {
      "ticker": "GOOGL",
      "eps": 340.7016694474644,
      "pe": 18.60584155353041,
      "pbr": 2.090571621381369,
      "beta": 1.6658783105196715,
      "regularPrice": 673.8230533109044,
      "priceSales": 4.923715433030362
    },
    {
      "ticker": "META",
      "eps": 415.50893669236257,
      "pe": 15.415731603070892,
      "pbr": 0.5486649150060776,
      "beta": 1.758664739242671,
      "regularPrice": 656.805019055196,
      "priceSales": 42.0552072350568
    },
    {
      "ticker": "TSLA",
      "eps": 67.99106144654199,
      "pe": 13.363433188115836,
      "pbr": 0.2143499461515992,
      "beta": 0.16126093032753763,
      "regularPrice": 314.73036884689185,
      "priceSales": 18.874005597297838
    },
    {
      "ticker": "NVDA",
      "eps": 19.150691694957978,
      "pe": 20.52232810640373,
      "pbr": 2.9102534551402623,
      "beta": 0.5756169808998042,
      "regularPrice": 649.248931849608,
      "priceSales": 45.964548804905526
    },
    {
      "ticker": "ADBE",
      "eps": 204.68842612853433,
      "pe": 39.822929131606784,
      "pbr": 3.287879677988287,
      "beta": 1.8701565181015427,
      "regularPrice": 425.9745068565485,
      "priceSales": 19.73876087840718
    },
    {
      "ticker": "NFLX",
      "eps": 498.92212968359837,
      "pe": 16.540578596067878,
      "pbr": 3.272061576452205,
      "beta": 0.028097993001439114,
      "regularPrice": 415.7960897391166,
      "priceSales": 54.676235487636646
    },
    {
      "ticker": "INTC",
      "eps": 41.64189256927886,
      "pe": 21.569633129317577,
      "pbr": 1.958161366048909,
      "beta": 1.0798512930255604,
      "regularPrice": 1.5289876269994451,
      "priceSales": 84.38585189595437
    },
    {
      "ticker": "CSCO",
      "eps": 336.81007963063723,
      "pe": 21.064383529026422,
      "pbr": 2.0245190974821856,
      "beta": 1.3691301992799825,
      "regularPrice": 567.4428517755406,
      "priceSales": 92.34883287325346
    },
    {
      "ticker": "CRM",
      "eps": 363.46743115093415,
      "pe": 47.851393431318556,
      "pbr": 0.34491412519553055,
      "beta": 1.1229327743090827,
      "regularPrice": 668.8003896053074,
      "priceSales": 65.51561190630086
    },
    {
      "ticker": "ORCL",
      "eps": 150.92623941866233,
      "pe": 28.51529407795329,
      "pbr": 2.9475461842763035,
      "beta": 0.7344871641569499,
      "regularPrice": 592.0379351339501,
      "priceSales": 68.97340072715646
    },
    {
      "ticker": "IBM",
      "eps": 171.7974096279713,
      "pe": 6.521295151137075,
      "pbr": 1.9970698530780346,
      "beta": 0.9112723265164462,
      "regularPrice": 39.414204798665594,
      "priceSales": 18.088332773347958
    },
    {
      "ticker": "ZM",
      "eps": 491.7135750636029,
      "pe": 26.6205273048269,
      "pbr": 3.2876918947176152,
      "beta": 0.45838651111171336,
      "regularPrice": 434.13530858414117,
      "priceSales": 13.84657058419747
    },
    {
      "ticker": "PFE",
      "eps": 429.96635324591614,
      "pe": 15.841207986334293,
      "pbr": 3.140105854318551,
      "beta": 1.6407089940569435,
      "regularPrice": 671.4543646367998,
      "priceSales": 20.69541306543141
    },
    {
      "ticker": "JNJ",
      "eps": 349.0959675485771,
      "pe": 20.98877386164377,
      "pbr": 3.3308863880193327,
      "beta": 1.4457917281713835,
      "regularPrice": 483.2895878830051,
      "priceSales": 32.06663558493399
    },
    {
      "ticker": "MRNA",
      "eps": 111.96505184742722,
      "pe": 5.232813618089793,
      "pbr": 1.6252829052757543,
      "beta": 1.7303919044320581,
      "regularPrice": 674.9535542807552,
      "priceSales": 64.27175473133471
    },
    {
      "ticker": "MRK",
      "eps": 374.56571627358403,
      "pe": 1.2095219804356239,
      "pbr": 3.112171886225209,
      "beta": 1.035703485413321,
      "regularPrice": 584.1443431937637,
      "priceSales": 54.91098005494499
    },
    {
      "ticker": "ABT",
      "eps": 321.89899636152506,
      "pe": 15.439333937065857,
      "pbr": 2.1615359216646652,
      "beta": 0.3187830276719601,
      "regularPrice": 552.8220160412216,
      "priceSales": 29.774554765091253
    },
    {
      "ticker": "PG",
      "eps": 231.24873276146985,
      "pe": 29.808542941605076,
      "pbr": 1.444076393871586,
      "beta": 1.2191475266317435,
      "regularPrice": 304.64921935250413,
      "priceSales": 3.109073106828686
    },
    {
      "ticker": "KO",
      "eps": 162.22371057792816,
      "pe": 4.1899100303074865,
      "pbr": 3.7266463338051548,
      "beta": 0.15714773699116114,
      "regularPrice": 195.04916893801283,
      "priceSales": 26.71387303479955
    },
    {
      "ticker": "PEP",
      "eps": 221.9625978206573,
      "pe": 46.41190675094773,
      "pbr": 1.4347814467559168,
      "beta": 1.5420554755846743,
      "regularPrice": 137.98418908613243,
      "priceSales": 5.7826992985950065
    },
    {
      "ticker": "UL",
      "eps": 242.78773389728926,
      "pe": 45.42498351222729,
      "pbr": 0.07766273645315636,
      "beta": 1.7440891743941087,
      "regularPrice": 415.52080015299157,
      "priceSales": 22.897582610018464
    },
    {
      "ticker": "NSRGY",
      "eps": 315.5934840881146,
      "pe": 45.19499830047439,
      "pbr": 3.865290018848519,
      "beta": 0.9131630406758888,
      "regularPrice": 598.9612510377677,
      "priceSales": 89.61489547594053
    },
    {
      "ticker": "MCD",
      "eps": 427.714281856598,
      "pe": 22.353621150620107,
      "pbr": 0.045171578256723244,
      "beta": 0.9316713882962451,
      "regularPrice": 666.2859876827483,
      "priceSales": 49.219453098098654
    },
    {
      "ticker": "SBUX",
      "eps": 334.94908521774556,
      "pe": 35.444154591022645,
      "pbr": 2.6103085149691996,
      "beta": 0.8831029864944977,
      "regularPrice": 644.7069488606613,
      "priceSales": 81.20292821834119
    },
    {
      "ticker": "WMT",
      "eps": 422.4656215911403,
      "pe": 32.78729730397594,
      "pbr": 3.4193423488550305,
      "beta": 1.5812813559865324,
      "regularPrice": 538.3554316260665,
      "priceSales": 61.646730608112364
    },
    {
      "ticker": "TGT",
      "eps": 88.45832675509813,
      "pe": 40.867605098811055,
      "pbr": 1.6727626760939822,
      "beta": 1.015995078018173,
      "regularPrice": 151.7638097313819,
      "priceSales": 49.741361050572095
    },
    {
      "ticker": "HD",
      "eps": 232.0773149876989,
      "pe": 20.146140051781426,
      "pbr": 2.415580532396783,
      "beta": 1.438870494784108,
      "regularPrice": 406.9336055219626,
      "priceSales": 78.95854594479661
    },
    {
      "ticker": "LOW",
      "eps": 220.4498160126025,
      "pe": 28.5397896750029,
      "pbr": 0.9294513943400764,
      "beta": 1.0096896070694332,
      "regularPrice": 338.4575759378035,
      "priceSales": 30.580314467761017
    },
    {
      "ticker": "COST",
      "eps": 238.95581435334856,
      "pe": 40.02025111023857,
      "pbr": 3.1506059153244044,
      "beta": 0.32141475042154966,
      "regularPrice": 21.866897694022125,
      "priceSales": 71.48202174089546
    },
    {
      "ticker": "JPM",
      "eps": 310.14671962983687,
      "pe": 49.433525778238696,
      "pbr": 0.9041439780212572,
      "beta": 1.8701577593084586,
      "regularPrice": 663.0699277541314,
      "priceSales": 24.03589638851209
    },
    {
      "ticker": "BAC",
      "eps": 284.6376519399145,
      "pe": 2.612260762138696,
      "pbr": 2.861291013635248,
      "beta": 1.623579611600646,
      "regularPrice": 572.0194893840869,
      "priceSales": 95.95580004515026
    },
    {
      "ticker": "WFC",
      "eps": 36.013198761617794,
      "pe": 28.51091302403742,
      "pbr": 0.7399161696887466,
      "beta": 1.8537152594865103,
      "regularPrice": 219.18021437864564,
      "priceSales": 56.54406760262096
    },
    {
      "ticker": "GS",
      "eps": 222.4182302673593,
      "pe": 40.657640645331774,
      "pbr": 0.6079805030272736,
      "beta": 1.0346926326869106,
      "regularPrice": 559.6166493067716,
      "priceSales": 42.98331918281389
    },
    {
      "ticker": "MS",
      "eps": 454.51869305506875,
      "pe": 31.497044302221134,
      "pbr": 3.3069022191998334,
      "beta": 0.16422591347834636,
      "regularPrice": 26.992424412479686,
      "priceSales": 8.906054966589604
    },
    {
      "ticker": "V",
      "eps": 15.33616166271723,
      "pe": 20.748379714297137,
      "pbr": 3.994350228940136,
      "beta": 0.9919640825859366,
      "regularPrice": 361.29473626434816,
      "priceSales": 94.39888053764385
    },
    {
      "ticker": "MA",
      "eps": 467.72799523206675,
      "pe": 3.3874276913353207,
      "pbr": 2.809006359277607,
      "beta": 0.11698834263396973,
      "regularPrice": 272.57239920841283,
      "priceSales": 71.548543613656
    },
    {
      "ticker": "PYPL",
      "eps": 204.72983930744383,
      "pe": 46.25837326020604,
      "pbr": 2.964778530842162,
      "beta": 0.295385469355784,
      "regularPrice": 117.21085173565943,
      "priceSales": 95.64002732587366
    },
    {
      "ticker": "AXP",
      "eps": 13.24587146836398,
      "pe": 7.122289166150209,
      "pbr": 2.966448603933726,
      "beta": 0.3713075427015866,
      "regularPrice": 333.3192043994106,
      "priceSales": 30.86443065589153
    },
    {
      "ticker": "XOM",
      "eps": 207.39325061226387,
      "pe": 10.958183491963858,
      "pbr": 2.5301894915380796,
      "beta": 0.36108153290894585,
      "regularPrice": 268.8436805616183,
      "priceSales": 8.734135060902414
    },
    {
      "ticker": "CVX",
      "eps": 10.801043720127023,
      "pe": 1.9347375779352416,
      "pbr": 2.9093130646686385,
      "beta": 1.2137778182823635,
      "regularPrice": 2.7935787969820325,
      "priceSales": 42.69459275717749
    },
    {
      "ticker": "BP",
      "eps": 38.113623808642025,
      "pe": 0.8748559911948117,
      "pbr": 2.5187843866504123,
      "beta": 0.7689818806424937,
      "regularPrice": 79.25622600323148,
      "priceSales": 23.428121460923947
    },
    {
      "ticker": "SPY",
      "eps": 215.1864341368045,
      "pe": 17.061245501065127,
      "pbr": 0.7820816253939187,
      "beta": 1.5291217928073304,
      "regularPrice": 676.9550820363705,
      "priceSales": 18.275464832756928
    },
    {
      "ticker": "META",
      "eps": 100.09628031255346,
      "pe": 16.765423308252814,
      "pbr": 3.348673195996902,
      "beta": 1.0093512560279407,
      "regularPrice": 641.9940613068751,
      "priceSales": 77.98338738943605
    },
    {
      "ticker": "F",
      "eps": 363.38994451606345,
      "pe": 9.417642969749828,
      "pbr": 3.8858849751688993,
      "beta": 1.6380768056600088,
      "regularPrice": 474.99714645570464,
      "priceSales": 52.93502787123352
    },
    {
      "ticker": "GM",
      "eps": 187.16910245835962,
      "pe": 37.38681318319262,
      "pbr": 1.4679030129731006,
      "beta": 1.8525025989184334,
      "regularPrice": 153.66394212922324,
      "priceSales": 94.15208269698769
    },
    {
      "ticker": "TM",
      "eps": 26.83220909364237,
      "pe": 8.220308056983395,
      "pbr": 3.4542622467907913,
      "beta": 1.320060580924765,
      "regularPrice": 520.202309778638,
      "priceSales": 84.3833185172272
    },
    {
      "ticker": "HMC",
      "eps": 27.937615397853,
      "pe": 42.558282978751706,
      "pbr": 3.484497563289586,
      "beta": 0.7530094147855877,
      "regularPrice": 45.55166729168511,
      "priceSales": 98.52017820742766
    }
  ]

  export default stockData;